@import "colors";


.searchProductForm {
    margin : 1em;
}

.md {
    ul {
        list-style : initial!important;
        margin : 1ex 0 1ex 1em; 
        padding : initial!important; 
    }
    li {
        margin : initial!important;
        padding : initial!important;
        border : unset!important;        
    }
}

.quotationEditor, .searchProductForm {
    fieldset {
        border : none;
        padding : 1ex;

        label {
            font-weight : bold;
        }

        & > * {
            display : block;
            width : 100%;                
        }

        textarea {
            width : 100%;
            height  : 8em;
        }

        #preview {
            margin : 0;
        }
    }

    hgroup {
        h4, h5 {
            margin : 0;            
        }     
        
        h5 {
            font-weight: normal;
            font-style: italic;
        }
    }

    .product {
        background: rgba($color-silver, 0.1);

        border : 1px solid $color-silver;
        border-width: 1px 0;
        padding : 3px;
        margin-bottom : 2em;
    }

    .bulkDiscountList {
        padding : 0;
        margin : 1ex 0 0 0;
        color : blueviolet;

        li {
            border : none!important;
        }
    }

    ul {
        list-style : none;
        margin : 1em;
        padding : 0;

        input {
            width : 5em;
        }

        li {
            position : relative;
                                                
            border : 1px solid $color-silver;
            border-width: 1px 0;

            margin-bottom: 3px;
            padding-left : 6em;


            figure {
                position : absolute;
                padding : 0;
                margin : 0;
                text-align: center;

                left : 0;
                top : 0;
                bottom : 0;
                width : 5.5em;

                img {
                    max-width : 5.5em;
                    max-height : 4em;
                }
            }

            
            .activeError {
                background : rgba($color-rouge, 0.85);

                text-align: center;
                padding : 3px 1ex;

                color : white;
                font-weight : bold;
            }

            &.shippingLine, &.totalsLine, &.additionalCostsLine {
                border : none;
            }

            .itemLine {
                display : flex;
                align-items: center;
                
                .space {
                    flex : 1 1 100%;
                }

                .description {
                    flex: 1 1 35%;
                    padding : 0 3px;
                }

                .quantity {
                    text-align: center;
                    flex: 1 1 10%;
                    white-space: nowrap;
                }

                .stockAlert {
                    color : white;
                    background: $color-dusty-orange;
                    
                    span {
                        font-weight : bold;
                        padding : 0 1ex;
                        white-space: nowrap;
                    }
                }

                .pricePer {
                    white-space: nowrap;
                    text-align: right;
                    flex: 1 1 25%;
                }

                .priceTotal {
                    white-space: nowrap;
                    text-align: right;
                    flex: 1 1 20%;
                }
                
                button {
                    margin-left: 1em;
                }

            }
        }
    }
}


.quotationEditor {
    .contactData {
        width : 50%;

        fieldset {
            width : 100%;
        }
    }

    .addresses {
        display : flex;
        flex-wrap: wrap;

        fieldset {
            flex: 0 1 50%;
            padding : 1ex;   
            margin : 0;         
        }               
    }

    .quotationLines {
        margin : 1em;
        padding : 0;
        list-style: none;

        li {
            position : relative;
                                                
            border : 1px solid $color-silver;
            border-width: 1px 0;

            margin-bottom: 3px;
            padding-left : 6em;


            figure {
                position : absolute;
                padding : 0;
                margin : 0;
                text-align: center;

                left : 0;
                top : 0;
                bottom : 0;
                width : 5.5em;

                img {
                    max-width : 5.5em;
                    max-height : 4em;
                }
            }

            
            .activeError {
                background : rgba($color-rouge, 0.85);

                text-align: center;
                padding : 3px 1ex;

                color : white;
                font-weight : bold;
            }

            &.shippingLine, &.totalsLine {
                border : none;
                margin : 1ex;
            }

            .itemLine {
                min-height: 4em;
                display : flex;
                align-items: center;

                &.labels {
                    min-height: 0;
                }
                
                .space {
                    flex : 1 1 100%;
                }

                .description {
                    flex: 1 1 35%;
                    padding : 0 3px;
                }

                .quantity {
                    text-align: center;
                    flex: 1 1 10%;

                    white-space: nowrap;
                }

                .stockAlert {
                    color : white;
                    background: $color-dusty-orange;
                    
                    span {
                        font-weight : bold;
                        padding : 0 1ex;
                        white-space: nowrap;
                    }
                }

                .pricePer {
                    white-space: nowrap;
                    text-align: right;
                    flex: 1 1 25%;
                }

                .priceTotal {
                    white-space: nowrap;
                    text-align: right;
                    flex: 1 1 20%;
                }

            }
        }
    }

    .saveQuotationForm {
        text-align : right;
        padding : 1ex;
        
        button {
            margin-left : 2em;
            padding: 6px 20px;
            cursor: pointer;
            color : $color-header-text;

            background : $color-header-bg;
            border: 1px solid $color-header-text;
            box-shadow: 0 2px 1px 0 lighten($color-header-text, 20%);

            border-radius: 3px;

            &:hover {
                background : darken($color-header-bg, 30%);
            }
        }

        svg {
            margin-right : 1em;
            vertical-align: middle;

            color :  $color-deep-green;
        }
    }
}